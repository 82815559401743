<template>
  <div class="announcement-list">
    <el-card>
      <!-- 工具栏 -->
      <div class="toolbar">
        <el-button type="primary" @click="handleAdd">添加公告</el-button>
      </div>

      <!-- PC端公告列表 -->
      <el-table 
        v-if="!isMobile"
        :data="tableData" 
        v-loading="loading"
      >
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="publishDate" label="发布日期" width="180">
          <template slot-scope="scope">
            {{ formatDate(scope.row.publishDate) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" class="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端公告列表 -->
      <div v-else class="mobile-list">
        <el-card 
          v-for="item in tableData" 
          :key="item.id" 
          class="mobile-item"
          shadow="hover"
        >
          <div class="mobile-item-content">
            <div class="mobile-item-title">{{ item.title }}</div>
            <div class="mobile-item-date">{{ formatDate(item.publishDate) }}</div>
          </div>
          <div class="mobile-item-actions">
            <el-button 
              type="primary" 
              size="mini" 
              @click="handleEdit(item)"
            >编辑</el-button>
            <el-button 
              type="danger" 
              size="mini" 
              @click="handleDelete(item)"
            >删除</el-button>
          </div>
        </el-card>
      </div>
    </el-card>

    <!-- 编辑对话框 -->
    <el-dialog 
      :title="dialogTitle" 
      :visible.sync="dialogVisible"
      :width="isMobile ? '95%' : '800px'"
      :fullscreen="isMobile"
      custom-class="announcement-dialog"
    >
      <el-form 
        :model="form" 
        :rules="rules" 
        ref="form" 
        label-width="80px"
        :label-position="isMobile ? 'top' : 'right'"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="发布日期" prop="publishDate">
          <el-date-picker
            v-model="form.publishDate"
            type="datetime"
            placeholder="选择发布日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="'00:00:00'"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <div style="border: 1px solid #ccc">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 300px; overflow-y: auto;"
              v-model="form.content"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="handleCreated"
            />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button 
          @click="dialogVisible = false"
          :size="isMobile ? 'medium' : 'small'"
        >取 消</el-button>
        <el-button 
          type="primary" 
          @click="handleSubmit" 
          :loading="submitting"
          :size="isMobile ? 'medium' : 'small'"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  components: {
    Editor,
    Toolbar
  },
  data() {
    return {
      isMobile: false,
      loading: false,
      tableData: [],
      dialogVisible: false,
      dialogTitle: '添加公告',
      submitting: false,
      form: {
        id: null,
        title: '',
        content: '',
        publishDate: new Date().toISOString().slice(0, 19).replace('T', ' ')
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        publishDate: [
          { required: true, message: '请选择发布日期', trigger: 'change' }
        ]
      },
      editor: null,
      mode: 'default',
      toolbarConfig: {
        excludeKeys: [
          'uploadImage',
          'uploadVideo',
          'insertTable',
          'code',
          'codeBlock',
          'fullScreen'
        ]
      },
      editorConfig: {
        placeholder: '请输入公告内容...',
        scroll: true,
        MENU_CONF: {}
      }
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.fetchData()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
    if (this.editor == null) return
    this.editor.destroy()
  },
  methods: {
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },
    async fetchData() {
      this.loading = true
      try {
        const res = await this.$http.get('/api/admin/announcements')
        if (res.data.code === 200) {
          this.tableData = res.data.data.sort((a, b) => 
            new Date(b.publishDate) - new Date(a.publishDate)
          )
        }
      } catch (error) {
        console.error('获取公告列表失败:', error)
        this.$message.error('获取公告列表失败')
      } finally {
        this.loading = false
      }
    },
    handleAdd() {
      this.dialogTitle = '添加公告'
      this.form = {
        id: null,
        title: '',
        content: '',
        publishDate: new Date().toISOString().slice(0, 19).replace('T', ' ')
      }
      this.dialogVisible = true
    },
    handleEdit(row) {
      this.dialogTitle = '编辑公告'
      this.form = { ...row }
      this.dialogVisible = true
    },
    async handleSubmit() {
      try {
        await this.$refs.form.validate()
        this.submitting = true
        
        const api = this.form.id 
          ? `/api/admin/announcements/${this.form.id}`
          : '/api/admin/announcements'
        const method = this.form.id ? 'put' : 'post'
        
        const res = await this.$http[method](api, this.form)
        if (res.data.code === 200) {
          this.$message.success('保存成功')
          this.dialogVisible = false
          this.fetchData()
        }
      } catch (error) {
        console.error('保存失败:', error)
        this.$message.error('保存失败')
      } finally {
        this.submitting = false
      }
    },
    async handleDelete(row) {
      try {
        await this.$confirm('确认删除该公告?', '提示', this.getConfirmConfig('确认删除该公告?'))
        const res = await this.$http.delete(`/api/admin/announcements/${row.id}`)
        if (res.data.code === 200) {
          this.$message.success('删除成功')
          this.fetchData()
        }
      } catch (error) {
        if (error !== 'cancel') {
          console.error('删除失败:', error)
          this.$message.error('删除失败')
        }
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString()
    },
    handleCreated(editor) {
      this.editor = Object.seal(editor)
    },
    getConfirmConfig(message, title = '提示') {
      return {
        title,
        message,
        customClass: 'mobile-confirm-dialog',
        center: this.isMobile,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: this.isMobile ? 'confirm-button' : '',
        cancelButtonClass: this.isMobile ? 'cancel-button' : '',
        type: 'warning'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.announcement-list {
  padding: 20px;
  
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
}

.toolbar {
  margin-bottom: 20px;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
}

.mobile-list {
  .mobile-item {
    margin-bottom: 10px;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    .mobile-item-content {
      margin-bottom: 15px;
      
      .mobile-item-title {
        font-size: 16px;
        font-weight: 500;
        color: #303133;
        margin-bottom: 8px;
      }
      
      .mobile-item-date {
        font-size: 13px;
        color: #909399;
      }
    }
    
    .mobile-item-actions {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}

.danger {
  color: #F56C6C;
}

// 移动端弹窗样式
.announcement-dialog {
  @media screen and (max-width: 768px) {
    .el-dialog__body {
      padding: 15px !important;
    }
    
    .el-form-item {
      margin-bottom: 15px;
    }
    
    .el-form-item__label {
      padding-bottom: 8px;
      line-height: 1.2;
    }
    
    .dialog-footer {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      
      .el-button {
        flex: 1;
      }
    }
    
    // 富文本编辑器适配
    .ql-container {
      height: 200px !important;
    }
    
    .ql-toolbar {
      padding: 5px !important;
    }
  }
}

// 暗色主题适配
@media (prefers-color-scheme: dark) {
  .mobile-item {
    background-color: #1f2d3d;
    
    .mobile-item-title {
      color: #fff !important;
    }
    
    .mobile-item-date {
      color: #909399 !important;
    }
  }
}

/* 暗黑主题适配 */
.w-e-text-container, 
.w-e-toolbar {
  background-color: #fff !important;
}

@media (prefers-color-scheme: dark) {
  .w-e-text-container,
  .w-e-toolbar {
    background-color: #1f2d3d !important;
    color: #fff !important;
  }
  
  .w-e-toolbar {
    border-bottom: 1px solid #435266 !important;
  }
  
  .w-e-text-container {
    border: 1px solid #435266 !important;
  }
}

/* 添加编辑器滚动条样式 */
.w-e-text-container {
  overflow-y: auto !important;
}

.w-e-text-container::-webkit-scrollbar {
  width: 6px;
}

.w-e-text-container::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}

.w-e-text-container::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
</style> 